/**
 * 异动资讯封装
 * 入参：infoOption {tableName： 'aac_f', keyValue: {aacno: '***001'}} //表名 主键
 */
import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';

@Component({
  selector: 'app-software-expiration-reminder',
  template: `
   <div class="popup" id="software1">
    <div class="content"  >
      <div class="content-style1">
        <div class="content-style2">
          <img class="content-style3" src="assets/img/home/注意.JPG">
          <div class="content-style4">{{title1}}</div>
        </div>
        <div *ngIf="!(aav007 == '2' && flag == '2')" class="content-style5" (click)="close1()">
            <a class="content-style6"><i class="content-style7" class="anticon anticon-close"></i></a>
        </div>
      </div>
      <div class="content-style8">
        <img class="content-style9" src="assets/img/home/电脑提醒.JPG">
      </div>

      <div  class="content-style11">
        <div class="content-style12">
             <div class="content-style13">
              <div *ngIf="flag == '1'" class="content-style14">您好，软件距离到期时间仅有</div>
              <div *ngIf="flag == '2'" class="content-style14">您好，软件已过期</div>
              <div class="content-style15">{{c_S088}}</div> 
              <div class="content-style16">天</div>
     
            </div>
          </div>
             <div class="content-style17">{{content}}</div>
             <div class="content-style18">{{customizeContent}}</div>
       </div>

       <div class="content-style19"></div>
       <div class="content-style20">
          <div class="content-style21">
            <img class="content-style22" src="assets/img/home/时间.png">
             <div class="content-style23">{{nowTime}}</div>
          </div>
          <div class="content-style24" *ngIf="!(aav007 == '2' && flag == '2')">
               <a class="content-style26"></a>
              <a  class="content-style27" (click)="close1()">确定</a>
          </div>
       </div>
    </div>
    </div>

   <div class="popup2" id="software2">
   <div class="content">
      <div class="content-style1">
        <div class="content-style2">
          <img class="content-style3" src="assets/img/home/注意.JPG">
          <div class="content-style4">{{title2}}</div>
        </div>
        <div class="content-style5" (click)="close2()">
            <a class="content-style6"><i class="content-style7" class="anticon anticon-close"></i></a>
        </div>
      </div>
      <div class="content-style8">
        <img class="content-style9" src="assets/img/home/电脑提醒.JPG">
      </div>
      <div class="content-style28">{{storeContent}}</div>
      <div class="content-style10">
        <table style="width: 90%;">
					<thead>
						<tr>
						<th style="width: 30%;">店铺</th>
						<th style="width: 30%;">订单号</th>
						<th style="width: 20%;">购买日期</th>
						<th style="width: 20%;">到期日期</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let val of ExpiredStoreList">
						<td>{{val.abz002}}({{val.abzstore}})</td>
						<td>{{val.abzord}}</td>
						<td>{{val.abzddate}}</td>
						<td>{{val.abzedate}}</td>
						</tr>
					</tbody>
				</table>
      </div>

       <div class="content-style19"></div>
       <div class="content-style20">
          <div class="content-style21">
            <img class="content-style22" src="assets/img/home/时间.png">
             <div class="content-style23">{{nowTime}}</div>
          </div>
          <div class="content-style24">
              <a (click)="exportStore()"  class="content-style25">导出</a>
              <a  class="content-style27" (click)="close2()">确定</a>
          </div>
       </div>
    </div>
  </div>
  `,
  styles: [`
   /* 弹框容器 */
.popup {
    display: flex; /* 使用Flexbox布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    position: fixed; /* 固定定位 */
    top: 40px; /* 顶部对齐 */
    left: 0; /* 左边对齐 */
    height: 100%; /* 高度100% */
    width: 100%; /* 宽度100% */
    background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
    z-index: 99999; /* 确保在其他内容之上 */
    display:none;
  }

  .popup2 {
    display: flex; /* 使用Flexbox布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    position: fixed; /* 固定定位 */
    top: 40px; /* 顶部对齐 */
    left: 0; /* 左边对齐 */
    height: 100%; /* 高度100% */
    width: 100%; /* 宽度100% */
    background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
    z-index: 99998; /* 确保在其他内容之上 */
    display:none;
  }
   
  /* 弹框内容 */
  .content {
    background-color: #fff; /* 弹框背景色 */
    padding: 10px; /* 内边距 */
    border-radius: 5px; /* 边框圆角 */
    width: 900px;
    height: 500px;
  }
  table, th, td {
    border: 1px solid #cccfd5;
    border-collapse: collapse; /* 移除单元格之间的间隔 */
  }
  th,td{
    padding-left: 5px;
  }
  .content-style1 {
       width: 100%;display: flex;
    }
    .content-style2 {
display: flex;width: 97%;align-items:center;
        }
     .content-style3 {
    width: 20px;height: 20px; 
        }
      .content-style4 {
    font-size: 18px;font-weight: 1000;margin-left: 10px;
        }
       .content-style5 {
    display: flex;width: 3%;
        }
        .content-style6 {
    font-size: 23px;
        }
         .content-style7 {
    color: #697081;
        }
          .content-style8 {
    display: flex;width: 100%;align-items:center;justify-content:center;
        }
           .content-style9 {
    width:120px;height:100px; 
        }
            .content-style10 {
    width: 100%;padding-left: 10%;height: 250px;overflow-y: scroll;margin-top: 10px; 
        }
    .content-style11 {
width: 100%;margin-top: 30px;height: 260px;overflow-y: scroll;
    }
       .content-style12 {
width: 100%;height: 50px;position: relative;font-size: 18px;
    }
          .content-style13 {
display: flex;width: 100%;display: flex;align-items:center;justify-content:center; 
    }
             .content-style14 {
margin-top: 12px; 
    }
                .content-style15 {
font-size: 30px;color: red;
    }
                   .content-style16 {
margin-top: 12px;
    }
                      .content-style17 {
width: 100%;font-size: 18px;padding-left: 10%;padding-right: 10%;
    }
                         .content-style18 {
width: 100%;font-size: 18px;padding-left: 10%;padding-right: 10%;margin-top: 10px; 
    }
                            .content-style19 {
width: 100%;height: 1px;background-color: #E0E1E4;margin-top: 10px;
    }
                               .content-style20 {
width: 100%;display: flex;height: 60px;align-items:center;padding-right: 20px;padding-left: 20px; 
    }
                                  .content-style21 {
width: 80%;display: flex;align-items:center; 
    }
                                     .content-style22 {
width: 15px;height: 15px; 
    }
                                        .content-style23 {
margin-left: 5px; 
    }
                                           .content-style24{
width: 20%;text-align: right;display: flex; 
    }
      .content-style25{
color: #1c1f25;
              width: 80px;
              height: 32px;
              border-radius: 4px;border: 1px solid #1c1f25;display: flex;align-items:center;justify-content:center;
    }
     .content-style25:hover{
         color: #1c1f25;
    }
      .content-style26{
width: 80px;
              height: 32px; 
    }
    .content-style27{
width: 80px;
              height: 32px;
              border-radius: 4px;display: flex;align-items:center;justify-content:center;
              background-color: #68a9ff;
              color: white;margin-left: 10px; 
    }
              .content-style27:hover{
                color: white;
              }
                .content-style28{
width: 100%;display: flex;justify-content:center;padding: 0px 10%;
    font-size: 15px;
    height: 23px;
    overflow-y: scroll;
                }
  `],
  encapsulation: ViewEncapsulation.None
})
export class SoftwareExpirationReminderComponent implements OnInit {

  public _infoOption: any;
  public _infoList: any;
  @Input() set infoOption(infoOption: any) {
    this._infoOption = infoOption;
  }
  get infoOption(): any { return this._infoOption; }
  public morelog_datas: any;
  showInfo = false;
  public ExpiredStoreList: Array<any> = [];
  public c_S088: any;
  public content: any;
  public customizeContent: any;
  public title1: any;
  public title2: any;
  public nowTime: any;
  public storeContent: any;
  public aav007 = '1';
  public flag= '1';

  constructor(
    public http: FxDapHttpClient) {
  }

  ngOnInit(): void {
    this.dataInit(); //获取初始数据
    var currentDate = new Date();
    var currentTime = currentDate.toLocaleString();
    this.nowTime = currentTime;
  }
  visible: boolean;

  clickMe(): void {
    this.visible = false;
  }

  change(value: boolean): void {
    console.log(value);
  }

  /**
   * dataInit
   */
  public dataInit(): void {


  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  public getExpiredStore(abzent): void {
    let param = { 'c_ent': abzent }
    this.http.post('E/IFxed009Service/getExpiredStore', { param: param }).subscribe(
      response => {
        this.ExpiredStoreList = response.data.data;
        this.title2 = '即将到期店铺（' + response.data.data[0].dayTime + '天内）';
        this.storeContent = response.data.data[0].storeContent;
        var div = document.getElementById('software2');
        if (this.ExpiredStoreList.length > 0) {
          div.style.display = 'flex';
        }
      }, error => {

      }
    );
  }

  public getExpiredEndTime(abzent): void {
    this.title1 = '系统提醒';
    let param = { 'c_ent': abzent}
    this.http.post('E/IFxed009Service/getExpiredEndTime', { param: param }).subscribe(
      response => {
        if (response.data&&response.data.data.length > 0) {
          this.aav007 = response.data.data[0].aav007;
          this.flag = response.data.data[0].flag;
          this.c_S088 = response.data.data[0].daynum;
          this.content = response.data.data[0].content;
          this.customizeContent = response.data.data[0].customizeContent;
          var div = document.getElementById('software1');
          div.style.display = 'flex';
        }
      }, error => {

      }
    );
  }

  public close1() {
    var div = document.getElementById('software1');
    div.style.display = 'none';
  }

  public close2() {
    var div = document.getElementById('software2');
    div.style.display = 'none';
  }

  public exportStore() {
    const exportParam = {
      'fileName': "到期店铺信息",                   // 导出名称
      'hasBorder': "false",  // 导出是否添加边框      
      'program_no': "fxai015",  // 作业编号
      'serviceList': [
        {
          'apiName': 'E/IFxed009Service/getExpiredStoreExport',
          'paramJson': { 'param': JSON.stringify(this.ExpiredStoreList) },
          'sheetName': "到期店铺信息"
        }
      ]
    }
    this.http.post('A/IExportExcelService/export', { param: exportParam }).subscribe(
      response => {
        if (response.success && response.data) {
          window.open(response.data);
        }
      });
  }
}

